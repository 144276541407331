/* Header */
.header {
  position: relative;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.logo__link {
  font-size: 16px;
  font-weight: 700;
  color: $dark;
}

.logo__image {
  max-height: 50px;
}

.nav__item {
  display: inline-block;
  margin-left: 30px;
  .nav__link {
    color: $dark;
  }
}

/* Mobile Nav */
.main-nav__open {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  @media (max-width: $mobile) {
    visibility: visible;
    opacity: 1;
  }
}

.main-nav__close {
  position: fixed;
  top: 30px;
  right: 20px;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}

@media (max-width: $mobile) {
  .main-nav__box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    background-color: $white;
    transition: 400ms ease-in-out;
    &.visible {
      visibility: visible;
      opacity: 1;

      .main-nav__close {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .nav__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 50vh;
    overflow: auto;
    .nav__item {
      display: block;
      margin-left: 0;
      margin-bottom: 30px;
      font-size: 27px;
      line-height: 33px;
      text-align: center;
    }
  }
}
