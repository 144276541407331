/*------------------------------------------------------------------
[TABLE OF CONTENTS]
-------------------------------------------------------------------*/

/* >>>>>>>>>>>>>>>>>>>>>>>>>>>
:: 1 Tools and Plugins
:: 2 Base Styles
:: 3 Modules
    3.1 Wrapper
    3.2 Header
    3.3 Author Section
    3.4 Footer
:: 4 Layouts
    4.1 Article
    4.2 Post
    4.3 Page
<<<<<<<<<<<<<<<<<<<<<<<<<<<< */


@import '0-settings/variables';
@import '0-settings/mixins';
@import '0-settings/helpers';


/* ==============  :: 1 Tools and Plugins ============== */
@import '1-tools/normalize';
@import '1-tools/reset';
@import '1-tools/shared';
@import '1-tools/syntax-highlighting';
@import '1-tools/grid';
@import '1-tools/aos';


/* ============== :: 2 Base Styles ============== */
@import '2-base/base';


/* ============== :: 3 Modules ============== */
/* :: 3.1 Wrapper */
@import '3-modules/wrapper';

/* :: 3.2 Header */
@import '3-modules/header';

/* :: 3.3 Author Section */
@import '3-modules/author-section';

/* :: 3.4 Footer */
@import '3-modules/footer';


/* ============== :: 4 Layouts ============== */
/* :: 4.1 Article */
@import '4-layouts/article';

/* :: 4.2 Post */
@import '4-layouts/post';

/* :: 4.3 Page */
@import '4-layouts/page';

/* :: 4.4 Tag Page */
@import '4-layouts/tag-page';
