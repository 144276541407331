// *****************
// Color Variables
// *****************
$primary-color: #000060;
$secondary-color: #ffd001;
$dark: #333;
$gray: #666;
$gray-dark: #999;
$gray-blue: #b3bbcb;
$gray-white: #f8f8f8;
$gray-lighten: #c7d0e2;
$white: #fff;
